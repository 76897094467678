import { Link } from '@remix-run/react'
import { aOrganization, useAtomValue } from '~/atoms'
import { cn } from '~/utils'

export default function OrganizationLogo({
  className,
  tabIndex,
}: {
  className?: string
  tabIndex?: number
}) {
  const organization = useAtomValue(aOrganization)
  return (
    <Link
      target={organization!.meta.website ? '_blank' : '_self'}
      to={organization!.meta.website || '/'}
      className="focus-ring-primary rounded-md"
      aria-hidden="true"
      tabIndex={tabIndex}>
      <img
        className={cn('dark:brightness-0 dark:invert', className)}
        src={
          organization!.meta.logo
            ? organization!.meta.logo!.url
            : '/resimplifi-logo.svg'
        }
        alt={organization!.name}
      />
    </Link>
  )
}
